import React from "react";
import { HostedFields } from "braintree-web";
import { BraintreeContextInterface } from "v3/context/hooks/useBraintreeProvider";

export const BraintreeContext = React.createContext<BraintreeContextInterface>({
  client: undefined,
  hostedFields: null,
  setClient: () => {},
  setHostedFields: (_fields: HostedFields) => {},
});
