import { useState, useCallback } from "react";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { client as braintreeClient, Client, HostedFields } from "braintree-web";
import { useCreatePaymentClientTokenMutation } from "v3/services/graphql/generated";
import { logClientSideError } from "@pepdirect/v3/helpers/logAndCaptureInSentry";

export interface BraintreeContextInterface {
  client?: Client;
  hostedFields: HostedFields | null;
  setClient: () => void;
  setHostedFields: (fields: HostedFields) => void;
}

export function useBraintreeProvider(
  apolloClient: ApolloClient<NormalizedCacheObject>
): {
  braintreeContextValue: BraintreeContextInterface;
} {
  const [client, setClient] = useState<Client | undefined>();
  const [hostedFields, setHostedFields] = useState<HostedFields | null>(null);
  const [getToken, { data }] = useCreatePaymentClientTokenMutation({
    client: apolloClient,
  });
  const token = data?.createPaymentClientToken?.token;

  const handleSetHostedFields = useCallback(
    (fields: HostedFields) => setHostedFields(fields),
    []
  );

  const handleSetClient = useCallback(async () => {
    try {
      if (!token) {
        await getToken();
      }
    } catch (e) {
      // logged in errorLink
    }

    try {
      if (token && !client) {
        const newClient = await braintreeClient.create({
          authorization: token,
        });
        setClient(newClient);
      }
    } catch (e) {
      logClientSideError(e);
    }
  }, [token, client, getToken]);

  const braintreeContextValue: BraintreeContextInterface = {
    client,
    hostedFields,
    setClient: handleSetClient,
    setHostedFields: handleSetHostedFields,
  };

  return { braintreeContextValue };
}
