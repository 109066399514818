import styled, { css } from "styled-components";
import { HexColor } from "../../../types/styles";

const iconStyles = css`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const StyledAlert = styled.div<{
  color: HexColor;
  small: boolean;
}>`
  background-color: ${({ color }) => color};
  border-radius: 5px;
  display: flex;
  gap: 8px;
  line-height: normal;
  padding: ${({ small }) => (small ? "4px 8px" : "12px")};
  position: relative;
`;

export const StyledIcon = styled.div<{ small: boolean }>`
  ${iconStyles}
  height: ${({ small }) => (small ? "20px" : "24px")};
  width: ${({ small }) => (small ? "20px" : "24px")};
`;

export const StyledCloseIcon = styled.div<{ small: boolean }>`
  ${iconStyles}
  height: ${({ small }) => (small ? "20px" : "24px")};
  width: ${({ small }) => (small ? "20px" : "24px")};
  padding: 4px 2px;
  cursor: pointer;
  margin-left: auto;
`;

export const StyledContent = styled.div<{ small: boolean }>`
  padding: ${({ small }) => (small ? `2px 0 2px` : `4px 0 4px`)};
`;

export const StyledText = styled.div<{
  color: HexColor;
  bold: boolean;
  closable: boolean;
  gutterBottom?: boolean;
}>`
  font-size: 13px;
  color: ${({ color }) => color};

  ${({ gutterBottom }) =>
    gutterBottom &&
    `
    margin-bottom: 4px;
  `}

  ${({ bold }) =>
    bold &&
    `
    font-weight: 700;
  `}

  ${({ closable }) =>
    closable &&
    `
    padding-right: 8px;
  `}
`;

export const StyledChildrenWrapper = styled.div<{
  color: HexColor;
}>`
  line-height: normal;
  font-size: 13px;
  &,
  * {
    color: ${({ color }) => color};
  }
`;
