import { HostedFields } from "braintree-web";
import { addBusinessDays, format } from "date-fns";
import currency from "currency.js";
import { GraphQLError } from "graphql";
import {
  AddressDetailsFormValues,
  ADDRESS_DETAILS_TYPE,
  getCountryReactSelectOption,
  getStateReactSelectOption,
  UnitedStatesOption,
} from "components/Forms/AddressDetailsForm";
import { getNonce } from "services/payment";
import {
  AddressFragment,
  CalculateOrderTaxesMutation,
  CalculateOrderTaxesMutationVariables,
  ContactInfoInput,
  CountriesAllowed,
  CreateOrderMutation,
  CreditCardFragment,
  DiscountsInput,
  EmbeddedAddressInput,
  ItemsInput,
  PaymentRequestInput,
  PaypalAccountFragment,
  PriceV2Fragment,
  PriceItemFragment,
  PriceInput,
  StatesConfig,
  SubmittedOrderQuery,
  SubmitOrderInput,
  SubmitOrderMutationVariables,
  TaxItem,
  VerifiedAddressFragment,
  CurrentUserQuery,
  PriceSubmittedOrderFragment,
  ApplePayCardFragment,
} from "services/graphql/generated";
import {
  BlockedItem,
  CartError,
  CartMinimumError,
} from "@pepdirect/shared/types";
import { getMappedErrorMsg } from "@pepdirect/helpers/error";
import { RegulatoryPrice, RegulatoryPrices, PriceSummary } from "types/price";
import { PAYMENT_TYPES, RegulatoryCodes } from "constants/payment";
import { errorCodes } from "constants/error";

import { APP_ID } from "./theme";

export const itemIsDiscountable = ({
  subscriptionIntervalInDays,
  subscriptionPrice,
  productPrice,
}: {
  subscriptionIntervalInDays?: number | null;
  productPrice: string;
  subscriptionPrice: string;
}): boolean => {
  return (
    !subscriptionIntervalInDays ||
    !!(subscriptionIntervalInDays && subscriptionPrice === productPrice)
  );
};

const formatItem = (
  priceItem:
    | PriceItemFragment
    | NonNullable<
        SubmittedOrderQuery["submittedOrder"]
      >["price"]["items"][number]
) => ({
  subscriptionIntervalInDays: priceItem.subscriptionIntervalInDays,
  productPrice: currency(priceItem?.item?.price || 0, {
    fromCents: true,
  }).format(),
  subscriptionPrice: currency(
    priceItem?.item?.subscriptionMetadata?.subscriptionPrice || 0,
    {
      fromCents: true,
    }
  ).format(),
});

export const getSubtotals = (priceItems: PriceV2Fragment["items"]) => {
  const discountableItems = priceItems.filter((priceItem) =>
    itemIsDiscountable(formatItem(priceItem))
  );
  const nonDiscountableItems = priceItems.filter(
    (priceItem) => !itemIsDiscountable(formatItem(priceItem))
  );
  const discountableSubTotal = discountableItems.reduce(
    (acc, item) => acc + (item?.basePrice || 0),
    0
  );
  const nonDiscountableSubTotal = nonDiscountableItems.reduce(
    (acc, { item, quantity }) => {
      const subscriptionPrice =
        item?.subscriptionMetadata?.subscriptionPrice || item?.price || 0;
      if (subscriptionPrice) {
        return acc + subscriptionPrice * (quantity || 0);
      }
      return acc;
    },
    0
  );

  return {
    discountableSubTotal,
    nonDiscountableSubTotal,
    subtotal: discountableSubTotal + nonDiscountableSubTotal,
  };
};

/**
 * Formats the PriceItems provided into a PriceSummary object to be displayed
 * for the user at checkout.
 * @param taxItems - introduced when we do the switchover on the
 * backend from TaxJar to Vertex. The impositionTypeId is important:
 *  1 = General Sales and Use Tax (basically, sales tax)
 *  35 = Beverage Tax (Alcohol or Soft Drinks)
 *  61 = Beverage Container Fee
 * If taxItems exists (with values), we should use it as the source
 * of truth for taxes. If it doesn't exist, we fall back on using
 * the "taxPrice" value provided in each item (legacy).
 * For the "regulatory fees" section in the order summary, we only
 * show the imposition types 35 and 61.
 */
export const getPriceSummary = (
  priceItems: PriceV2Fragment["items"],
  shipping: number,
  taxItems: PriceV2Fragment["taxItems"] = []
): PriceSummary => {
  const salesTaxItem = taxItems.find(
    ({ impositionTypeId }) => impositionTypeId === 1
  );
  const remainingTaxItems = taxItems.filter(
    ({ impositionTypeId }) => impositionTypeId !== 1
  );

  const { discountableSubTotal, nonDiscountableSubTotal, subtotal } =
    getSubtotals(priceItems);

  const discountableItems = priceItems.filter((priceItem) =>
    itemIsDiscountable(formatItem(priceItem))
  );

  const taxes = taxItems.length
    ? salesTaxItem?.amount || 0
    : priceItems.reduce((acc, { taxAmount }) => acc + (taxAmount || 0), 0);
  const regulatoryFees = getRegulatoryFees(remainingTaxItems);
  const discount = discountableItems.reduce(
    (acc, { discountAmount }) => acc + (discountAmount || 0),
    0
  );
  const total = subtotal + taxes + shipping + regulatoryFees.total - discount;

  return {
    userShippingPrice: shipping,
    userSubtotalPrice: subtotal,
    userTaxPrice: taxes,
    userTotalPrice: total,
    userDiscountPrice: discount,
    userRegulatoryPrices: regulatoryFees,
    userDiscountableSubtotal: discountableSubTotal,
    userNonDiscountableSubtotal: nonDiscountableSubTotal,
  };
};

export const getPriceSummaryFromSubmittedOrder = (
  price: PriceSubmittedOrderFragment
): PriceSummary => {
  const { shipping, total, taxItems, items } = price;

  const salesTaxItem = taxItems.find(
    ({ impositionTypeId }) => impositionTypeId === 1
  );
  const taxes = taxItems.length
    ? salesTaxItem?.amount || 0
    : items.reduce((acc, { taxAmount }) => acc + (taxAmount || 0), 0);

  const nonSubscriptionDiscount = items.reduce((acc, item) => {
    const isNonSubscriptionItem = !item.subscriptionIntervalInDays;
    if (isNonSubscriptionItem) {
      return acc + (item.discountAmount || 0);
    }
    return acc;
  }, 0);

  const remainingTaxItems = taxItems.filter(
    ({ impositionTypeId }) => impositionTypeId !== RegulatoryCodes.SALES_TAX
  );
  const regulatoryFees = getRegulatoryFees(remainingTaxItems);
  const { subtotal } = getSubtotals(price.items);
  return {
    userShippingPrice: shipping || 0,
    userSubtotalPrice: subtotal || 0,
    userTaxPrice: taxes || 0,
    userTotalPrice: total || 0,
    userDiscountPrice: nonSubscriptionDiscount,
    userRegulatoryPrices: regulatoryFees,
  };
};

export const getRegulatoryFees = (
  taxItems: TaxItem[] = []
): RegulatoryPrices => {
  const calculatedTotal = taxItems.reduce(
    (acc, { amount }) => acc + (amount || 0),
    0
  );
  const subtotals = taxItems.map(
    ({ impositionTypeId, amount, impositionValue }): RegulatoryPrice => {
      return {
        impositionTypeId: impositionTypeId || RegulatoryCodes.NONE,
        amount: amount || 0,
        impositionValue: impositionValue || "",
      };
    }
  );

  const regulatoryPrices: RegulatoryPrices = {
    total: calculatedTotal,
    subtotals,
  };
  return regulatoryPrices;
};

// needed for updateOrder and submitOrder input variables
const transformAddressToEmbeddedAddressInput = (
  addr: Partial<AddressDetailsFormValues> | AddressFragment
): EmbeddedAddressInput => {
  let countryCode = "";
  let state = "";

  if ("country" in addr) countryCode = addr.country?.value || "";
  else if ("countryCode" in addr) countryCode = addr.countryCode;

  if (addr.state) {
    state = typeof addr.state === "string" ? addr.state : addr.state.value;
  }

  const {
    city,
    company,
    firstName,
    lastName,
    line1,
    line2,
    nickname,
    specialInstructions,
    zip,
  } = addr;

  return {
    city,
    company,
    countryCode,
    firstName,
    lastName,
    line1,
    line2,
    nickname,
    specialInstructions,
    state,
    zip,
  };
};

export const getContactInfoInput = (
  ship: Partial<AddressDetailsFormValues> | AddressFragment,
  email: string,
  isShipValidated: boolean
): ContactInfoInput => {
  return {
    email,
    phone: ship.phoneNumber || "",
    shippingAddress: {
      ...transformAddressToEmbeddedAddressInput(ship),
      validated: isShipValidated,
    },
    ...("emailOptIn" in ship && { emailOptIn: ship.emailOptIn }),
  };
};

export interface GetSubmitOrderMutationVariablesArgs {
  deliveryDate: Date | null;
  email: string;
  gifteeEmail?: string;
  gifteeMessage?: string;
  giftorName?: string;
  invoiceAddressId?: string;
  isGift?: boolean;
  isShipValidated: boolean;
  orderId: string;
  paymentRequest: PaymentRequestInput;
  poNumber?: string;
  price: PriceV2Fragment;
  ship: Partial<AddressDetailsFormValues> | AddressFragment;
}

export const getSubmitOrderMutationVariables = ({
  deliveryDate,
  email,
  gifteeEmail,
  gifteeMessage,
  giftorName,
  invoiceAddressId,
  isGift,
  isShipValidated,
  orderId,
  paymentRequest,
  poNumber,
  price,
  ship,
}: GetSubmitOrderMutationVariablesArgs): SubmitOrderMutationVariables => {
  return {
    orderId,
    input: {
      contactInfo: getContactInfoInput(ship, email, isShipValidated),
      paymentRequest,
      price: transformPriceFragmentToInput(price),
      gifteeEmail,
      gifteeMessage,
      giftorName,
      isGift,
      ...(deliveryDate && {
        requested_delivery_date: format(new Date(deliveryDate), "yyyy-MM-dd"),
      }),
      ...(invoiceAddressId && { invoiceAddressId }),
      ...(poNumber && { customerPo: poNumber }),
    },
  };
};

export const getVerifiedShippingAddress = (
  verifiedAddr: VerifiedAddressFragment,
  userAddr?: EmbeddedAddressInput | null
): EmbeddedAddressInput => {
  const { __typename, errors, success, ...verifiedAddrRest } = verifiedAddr;
  const { nickname, specialInstructions } = userAddr || {};
  return {
    ...verifiedAddrRest,
    validated: true,
    nickname,
    specialInstructions,
  };
};

export const isOriginalAndVerifiedAddressSame = (
  originalAddr: EmbeddedAddressInput,
  verifiedAddr: VerifiedAddressFragment,
  isUnverifiedOkay = false
): boolean => {
  if (!originalAddr || !verifiedAddr) return false;

  // line2 is optional, so we get a false negative if we check null === "" etc.
  // so we only check equality if both fields exist, otherwise both fields DON'T
  // exist which means they are equal
  const isLine2Equal =
    (originalAddr.line2 &&
      verifiedAddr.line2 &&
      originalAddr.line2?.toLowerCase() ===
        verifiedAddr.line2?.toLowerCase()) ||
    true;

  return (
    originalAddr.line1?.toLowerCase() === verifiedAddr.line1.toLowerCase() &&
    isLine2Equal &&
    originalAddr.city?.toLowerCase() === verifiedAddr.city.toLowerCase() &&
    originalAddr.state?.toLowerCase() === verifiedAddr.state?.toLowerCase() &&
    (isUnverifiedOkay
      ? // If app allows unverified address and the first 5 digits of the
        // zip code matches, we'll consider them valid
        originalAddr.zip?.substring(0, 5).toLowerCase() ===
        verifiedAddr.zip.substring(0, 5).toLowerCase()
      : originalAddr.zip?.toLowerCase() === verifiedAddr.zip.toLowerCase()) &&
    originalAddr.countryCode?.toLowerCase() ===
      verifiedAddr.countryCode.toLowerCase()
  );
};

interface buildBraintreePaymentRequestArgs {
  userPaymentMethod?:
    | CreditCardFragment
    | PaypalAccountFragment
    | ApplePayCardFragment;
  bill?: AddressDetailsFormValues;
  nonce?: string;
  isBillingEqualShipping?: boolean;
  paymentMethod: PAYMENT_TYPES;
  saveCardForLater?: boolean;
}

export const buildBraintreePaymentRequest = ({
  userPaymentMethod,
  bill,
  nonce,
  isBillingEqualShipping,
  paymentMethod,
  saveCardForLater,
}: buildBraintreePaymentRequestArgs): PaymentRequestInput => {
  // saved payment method
  if (!nonce && userPaymentMethod) {
    return {
      paymentMethod,
      paymentToken: userPaymentMethod.token,
    };
  }
  // new payment method
  return {
    paymentMethod,
    ...(bill &&
      !isBillingEqualShipping && {
        billingAddress: transformAddressToEmbeddedAddressInput(bill),
      }),
    ...(nonce && { token: nonce }),
    isBillingEqualShipping,
    saveCardForLater: !!saveCardForLater,
  };
};

export const formatStatus = (status: string): string => {
  return status[0].toUpperCase() + status.slice(1).replace(/_/g, " ");
};

export const getDayFromToday: (daysFromToday: number) => Date = (
  daysFromToday
) => {
  return addBusinessDays(new Date(), daysFromToday);
};

/**
 * Form the paymentRequest for submitting an order for all types of payment.
 *
 * Currently we allow 6 ways to pay:
 * 1. new credit card with same bill as ship
 * 2. new credit card with new bill
 * 3. saved credit card
 * 4. new paypal
 * 5. saved paypal
 * 6. allocation
 */
export const getPaymentRequestForSubmit = async (
  hostedFields: HostedFields | null,
  paymentRequest: PaymentRequestInput,
  ship: AddressFragment | AddressDetailsFormValues,
  bill: AddressDetailsFormValues | null,
  cardholderName: string
): Promise<PaymentRequestInput | null> => {
  const { paymentMethod, paymentToken, isBillingEqualShipping } =
    paymentRequest;
  // get nonce (token) for new credit cards
  if (hostedFields && paymentMethod === "CREDIT_CARD" && !paymentToken) {
    const tokenizeBillingAddress = isBillingEqualShipping ? ship : bill;

    // something went wrong
    if (!tokenizeBillingAddress) return null;

    const payload = await getNonce(
      hostedFields,
      tokenizeBillingAddress,
      cardholderName
    );
    // Set the billing address and nonce on the payment request
    return {
      ...paymentRequest,
      // if billing address exists and billing does not equal shipping,
      // send the billing address on submit
      billingAddress:
        bill && !isBillingEqualShipping
          ? transformAddressToEmbeddedAddressInput(bill)
          : null,
      token: payload?.nonce,
    };
  }

  // otherwise return paymentRequest as-is
  // - saved credit card (should already have paymentToken)
  // - paypal (should already have paymentToken if saved or token if new)
  // - allocation (no tokens)
  return paymentRequest;
};

export const getCalculateOrderTaxesVariables = (
  submitOrderInput: SubmitOrderInput,
  orderId: string,
  cartId: string
): CalculateOrderTaxesMutationVariables => {
  const { contactInfo, paymentRequest, price } = submitOrderInput;
  const {
    isBillingEqualShipping,
    billingAddress: bill,
    paymentMethod,
    paymentToken,
    token,
  } = paymentRequest;

  return {
    orderId,
    cartId,
    contactInfo,
    paymentRequest: {
      isBillingEqualShipping,
      paymentMethod,
      paymentToken: paymentToken || token,
      billingAddress: bill && !isBillingEqualShipping ? bill : null,
    },
    price,
    ...(submitOrderInput.invoiceAddressId && {
      invoiceAddressId: submitOrderInput.invoiceAddressId,
    }),
  };
};

export const getPriceAfterTax = (
  calculatedOrder: NonNullable<
    NonNullable<CalculateOrderTaxesMutation["calculateOrderTaxes"]>["order"]
  >
): PriceV2Fragment => {
  const {
    price: { items, discounts, shipping, subtotal, tax, taxItems, total },
  } = calculatedOrder;

  // remove typename to avoid type collisions
  const priceItems = items.map((priceItem) => {
    const { __typename, ...rest } = priceItem;
    return rest;
  });

  return {
    items: priceItems,
    discounts,
    shipping,
    subtotal,
    tax,
    taxItems: taxItems.map((taxItem) => ({
      amount: taxItem.amount,
      impositionTypeId: taxItem.impositionTypeId,
    })),
    total,
  };
};

// fragment is from response, input is for request
export const transformPriceFragmentToInput = (
  price: PriceV2Fragment
): PriceInput => {
  const discounts = price.discounts.map((discount): DiscountsInput => {
    const { __typename, ...rest } = discount;
    return rest;
  });

  const items = price.items.map((priceItem): ItemsInput => {
    const { itemId, discountAmount, quantity, taxAmount } = priceItem;
    return {
      itemId,
      quantity,
      discountAmount,
      taxAmount,
    };
  });

  const { shipping, subtotal, tax, total } = price;

  return {
    discounts,
    items,
    shipping,
    subtotal,
    tax,
    total,
  };
};

export const getInitialValues = (
  order: CreateOrderMutation["createOrder"] | null | undefined,
  userInfo: CurrentUserQuery["currentUser"] | null,
  statesAllowed: StatesConfig[],
  countriesAllowed: CountriesAllowed[]
) => {
  if (!order) {
    return {};
  }
  const { contactInfo } = order;
  const { shippingAddress } = contactInfo || {};
  const email = userInfo?.email || contactInfo?.email;
  const phoneNumber = contactInfo?.phone;
  // country is a field on the form, and countryCode comes from contactInfo.
  // We don't want countryCode as an initial value on the form. so we remove it here.
  let shipNoCountryCode = shippingAddress;
  if (shippingAddress && "countryCode" in shippingAddress) {
    const { countryCode, ...restOfShip } = shippingAddress;
    shipNoCountryCode = restOfShip;
  }
  const { firstName, lastName } = userInfo || {};

  return {
    ...(firstName &&
      lastName && {
        giftForm: { giftorName: `${firstName || ""} ${lastName || ""}`.trim() },
      }),
    shippingAddress: {
      emailOptIn: false,
      __formType: ADDRESS_DETAILS_TYPE,
      ...(email && { email }),
      ...(phoneNumber && { phoneNumber }),
      ...(shippingAddress && {
        ...shipNoCountryCode,
        state: getStateReactSelectOption(
          statesAllowed,
          shippingAddress?.countryCode || "",
          shippingAddress?.state || ""
        ),
      }),
      country: shippingAddress?.countryCode
        ? getCountryReactSelectOption(
            countriesAllowed,
            shippingAddress.countryCode
          )
        : UnitedStatesOption,
    },
  };
};

interface SubmitOrderErrorExtensions {
  details?: SubmitOrderErrorDetail[];
}
interface SubmitOrderErrorDetail {
  id: string;
  inventory_qty?: number;
}

type SubmitOrderErrorMsgNames =
  | "itemZipBlockedVendor"
  | "itemZipBlocked"
  | "itemExcluded"
  | "itemOutOfStock"
  | "itemLowStock"
  | "cartBelowMinAmt"
  | "cartBelowMinQty";

const submitOrderErrorMsg: Record<SubmitOrderErrorMsgNames, string> = {
  // item level zip errors
  itemZipBlockedVendor: "vendor_config_zip_code_validation",
  itemZipBlocked: "item_zip_code_validation",
  // item level excluded error
  itemExcluded: "assortment_exclusion",
  // item level out of stock error
  itemOutOfStock: "item_out_of_stock",
  // item level low stock error
  itemLowStock: "item_low_stock",
  // cart level amount error
  cartBelowMinAmt: "cart_minimum_purchase_amount_validation",
  // cart level qty error
  cartBelowMinQty: "cart_minimum_purchase_qty_validation",
};

export const parseSubmitOrderGraphQLErrors = (
  graphQLErrors: GraphQLError[]
) => {
  let blockedItems: BlockedItem[] = [];
  let minimumError: CartMinimumError = null;
  let errorMsg = "";
  const cartErrors: CartError[] = [];

  graphQLErrors.forEach((error: GraphQLError) => {
    const { message } = error;
    // TODO: coordinate with BE to create generated error types
    const errorDetails: SubmitOrderErrorDetail[] =
      (error.extensions as SubmitOrderErrorExtensions)?.details || [];

    switch (message) {
      case submitOrderErrorMsg.itemZipBlockedVendor:
      case submitOrderErrorMsg.itemZipBlocked:
        blockedItems = blockedItems.concat(
          errorDetails.map(({ id }: SubmitOrderErrorDetail) => ({
            id,
            isZipError: true,
          }))
        );
        break;
      case submitOrderErrorMsg.itemExcluded:
        blockedItems = blockedItems.concat(
          errorDetails.map(({ id }: SubmitOrderErrorDetail) => ({
            id,
          }))
        );
        break;
      case submitOrderErrorMsg.itemOutOfStock:
        blockedItems = blockedItems.concat(
          errorDetails.map(({ id }: SubmitOrderErrorDetail) => ({
            id,
            isOutOfStock: true,
          }))
        );
        cartErrors.push("noStock");
        break;
      case submitOrderErrorMsg.itemLowStock:
        blockedItems = blockedItems.concat(
          errorDetails.map(({ id, inventory_qty }: SubmitOrderErrorDetail) => ({
            id,
            inventoryQty: inventory_qty,
            isLowStock: true,
          }))
        );
        cartErrors.push("lowStock");
        break;
      case submitOrderErrorMsg.cartBelowMinAmt:
        minimumError = "amount";
        break;
      case submitOrderErrorMsg.cartBelowMinQty:
        minimumError = "quantity";
        break;
      default:
        errorMsg = getMappedErrorMsg(message, errorCodes, "submitOrder");
    }
  });

  return {
    blockedItems,
    minimumError,
    errorMsg,
    cartErrors,
  };
};

/**
 * REMOVE THIS WHEN WE HAVE BUNDLE BUILDER PLEASE B2B-3546
 * @param itemTitle
 * @returns
 */
export const isGxPod = (itemTitle = "") => {
  const title = itemTitle.toLowerCase();
  return !!title.match(/pod/) && !title.match(/bottle/);
};

/**
 * REMOVE THIS WHEN WE HAVE BUNDLE BUILDER PLEASE B2B-3546
 * @param priceItems
 * @param appId
 * @returns
 */

interface PriceItemsNeedSortingItem {
  item?: {
    title?: string | null;
  } | null;
}
export const priceItemsNeedSorting = (
  priceItems: PriceItemsNeedSortingItem[],
  appId: string | null
): boolean => {
  if (appId !== APP_ID.GatoradeCom) return false;

  return priceItems.some((item) => isGxPod(item.item?.title || ""));
};

/**
 * REMOVE THIS WHEN WE HAVE BUNDLE BUILDER PLEASE B2B-3546
 * @param priceItems
 * @returns a sorted list; Gx Pods moved to the end
 */
export function sortGatoradePriceItems<T extends PriceItemsNeedSortingItem>(
  priceItems: T[]
): T[] {
  const pods = priceItems.filter((item) => isGxPod(item.item?.title || ""));
  const nonPods = priceItems.filter((item) => !isGxPod(item.item?.title || ""));
  return nonPods.concat(pods);
}
