import styled from "styled-components";
import Link from "next/link";
import { InternalLinkProps } from ".";
import { WithTransientProps } from "../../../helpers/transientProps";

type InternalLinkWithTransientProps = WithTransientProps<
  InternalLinkProps,
  "fill" | "center" | "underline" | "uppercase" | "noWrap"
>;

export const StyledLink = styled(Link)<InternalLinkWithTransientProps>`
  ${({ color }) => color && `color: ${color};`}
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ $underline }) => $underline && `text-decoration: underline;`}
  ${({ $uppercase }) => $uppercase && `text-transform: uppercase;`}
  ${({ $noWrap }) => $noWrap && `white-space: nowrap;`}
  ${({ $center }) =>
    $center &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};
  ${({ $fill }) =>
    $fill &&
    `
    height: 100%;
    width: 100%;
  `};
`;
