import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import Link, { LinkProps } from "next/link";
import { WithTransientProps } from "../../helpers/transientProps";

type InternalLinkWithTransientProps = WithTransientProps<
  InternalLinkProps,
  "fill" | "center" | "underline" | "uppercase" | "noWrap"
>;

const StyledLink = styled(Link)<InternalLinkWithTransientProps>`
  ${({ color }) => color && `color: ${color};`}

  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}

  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}

  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}

  ${({ $center }) =>
    $center &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};

  ${({ $fill }) =>
    $fill &&
    `
    height: 100%;
    width: 100%;
  `};

  ${({ $underline }) => $underline && `text-decoration: underline;`}

  ${({ $uppercase }) => $uppercase && `text-transform: uppercase;`}

  ${({ $noWrap }) => $noWrap && `white-space: nowrap;`}
`;

interface InternalLinkProps extends LinkProps {
  children?: ReactNode;
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  center?: boolean;
  fill?: boolean;
  // `fill` is an actual DOM element that is reserved for a non-boolean
  // value. Adding a transient prop of `$fill` so styled-components doesn't
  // place `fill` in the DOM but the consumer of <InternalLink> still gets
  // to declare the prop `fill` ( see usage below: $fill={fill} )
  // https://styled-components.com/docs/api#transient-props
  $fill?: boolean;
  underline?: boolean;
  uppercase?: boolean;
  noWrap?: boolean;
  forwardedRef?: React.RefObject<HTMLAnchorElement | HTMLButtonElement | null>;
  onClick?: () => void;
  // https://styled-components.com/docs/advanced#styling-normal-react-components
  // this is needed to extend styles, ex:
  //
  // const StyledInternalLink = styled(InternalLink)`
  //   color: blue;
  // `;
  className?: string;
}

/**
 * https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/402#issuecomment-778567785
 * example usage
 * <InternalLink href="/about" color="#003069" center>About</InternalLink>
 *
 * For internal links that looks like buttons, but are NOT actual buttons in
 * the DOM (because you should not nest interactive components) please use:
 * <Button internalLink="/" type="secondary">
 */
export const InternalLink: FC<InternalLinkProps> = ({
  color = "",
  fontFamily = "",
  fontSize = "",
  fontWeight = "",
  center = false,
  fill = false,
  underline = false,
  uppercase = false,
  noWrap = false,
  onClick,
  children,
  className,
  ...linkProps
}) => {
  return (
    <StyledLink
      {...linkProps}
      color={color}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      $center={center}
      $fill={fill}
      $underline={underline}
      $uppercase={uppercase}
      $noWrap={noWrap}
      onClick={onClick}
      data-testid="internal-link-anchor"
      className={className || ""}
    >
      {children}
    </StyledLink>
  );
};
