/* eslint-disable no-case-declarations */
import { ThemeInterface } from "@pepdirect/v3/theme";
import { appIds } from "@pepdirect/v3/constants";

export const getTheme = async (
  appId: string | null
): Promise<ThemeInterface> => {
  switch (appId) {
    case appIds.GatoradeCom:
      const { GatoradeTheme } = await import(
        "@pepdirect/v3/theme/GatoradeTheme"
      );
      return GatoradeTheme;
    case appIds.GatoradeTeamzone:
      const { GatoradeTeamZoneTheme } = await import(
        "@pepdirect/v3/theme/GatoradeTeamZoneTheme"
      );
      return GatoradeTeamZoneTheme;
    case appIds.PantryShop:
      const { PantryShopTheme } = await import(
        "@pepdirect/v3/theme/PantryShopTheme"
      );
      return PantryShopTheme;
    case appIds.PepsiCoShop:
      const { PepsiCoShopTheme } = await import(
        "@pepdirect/v3/theme/PepsiCoShopTheme"
      );
      return PepsiCoShopTheme;
    case appIds.RockstarEnergy:
      const { RockstarEnergyTheme } = await import(
        "@pepdirect/v3/theme/RockstarEnergyTheme"
      );
      return RockstarEnergyTheme;
    case appIds.SodaStreamPL:
    case appIds.SodaStreamUS:
      const { SodaStreamTheme } = await import(
        "@pepdirect/v3/theme/SodaStreamTheme"
      );
      return SodaStreamTheme;
    default:
      const { PepDefaultTheme } = await import(
        "@pepdirect/v3/theme/PepDefaultTheme"
      );
      return PepDefaultTheme;
  }
};
