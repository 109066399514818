import React, { FC, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { sanitizeUrl } from "@braintree/sanitize-url";
import { Button } from "@pepdirect/v3/ui/Button";
import { parseLoginGraphQLErrors } from "@pepdirect/v3/helpers/errors";
import { LocalizationContext } from "v3/context/localization";
import { useLoginUserMutation } from "v3/services/graphql/generated";
import { getRedirectUrl } from "v3/helpers/routing";
import { TenantContext } from "v3/context/tenant";
import {
  StyledText,
  StyledOuterWrapper,
  StyledInnerWrapper,
  StyledExternalLink,
} from "./index.styled";
import { GraphQLError } from "graphql";
import { Heading } from "@pepdirect/v3/ui/Heading";
import { Alert } from "@pepdirect/v3/ui/Alert";
import { PageRoutes } from "v3/constants/pageRoutes";

type Props = {
  email: string;
  password: string;
};

export const AccountVerification: FC<Props> = ({ email, password }) => {
  const [loginMutation] = useLoginUserMutation();
  const [showAlert, setShowAlert] = useState(false); // Temporarily using this alert because designs were not provided
  const router = useRouter();
  const { localization } = useContext(LocalizationContext);
  const { tenant } = useContext(TenantContext);
  const {
    heading = "Account verification required",
    body = "Your account has been created, but needs to be verified to continue. Please check your email (and spam folder) for a link to verify your account and proceed.",
    buttonText = "Resend verification email",
    contactSupportText = "Contact support",
    emailSentText = "Email sent!",
  } = localization?.portal?.accountVerification || {};
  const { successRedirectTo, successRedirectSite } = router.query;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  }, [showAlert]);

  const callLoginMutation = async () => {
    await loginMutation({
      variables: {
        email,
        password,
      },
    })
      .then(() => {
        const redirectUrl = getRedirectUrl(
          successRedirectTo && sanitizeUrl(successRedirectTo as string),
          successRedirectSite && sanitizeUrl(successRedirectSite as string),
          tenant?.fallbackUrl
        );
        router.push(redirectUrl || PageRoutes.account);
      })
      .catch((e) => {
        const { emailVerificationRequired } = parseLoginGraphQLErrors(
          e.graphQLErrors as GraphQLError[],
          localization?.forms?.signIn
        );
        if (emailVerificationRequired) {
          setShowAlert(true);
        }
      });
  };

  return (
    <StyledOuterWrapper>
      <StyledInnerWrapper>
        <Heading center level="h1" size="xl">
          {heading}
        </Heading>
        <StyledText>{body}</StyledText>
        <Button onClick={callLoginMutation}>{buttonText}</Button>
        <StyledExternalLink
          underline
          href={`mailto:${tenant?.brandConfig.supportEmail || ""}`}
        >
          {contactSupportText}
        </StyledExternalLink>
        {showAlert && <Alert>{emailSentText}</Alert>}
      </StyledInnerWrapper>
    </StyledOuterWrapper>
  );
};

export default AccountVerification;
