import { GraphQLError } from "graphql";
import { ApolloError, GraphQLErrors } from "@apollo/client/errors";
import { convertFromCentsToDollars } from "@pepdirect/v3/helpers/currency";
import {
  logChangesetError,
  logUnknownErrorCode,
} from "@pepdirect/v3/helpers/logAndCaptureInSentry";
import {
  ItemErrorMsg,
  ItemWithErrorMsgs,
  GenericErrorExtensions,
  ChangesetErrorExtensions,
  ChangesetErrorKey,
  PersistTemporaryCartErrorKeys,
} from "@pepdirect/v3/types";
import {
  ApplyDiscountErrorKeys,
  ApplySavedShippingAddressErrorKeys,
  AuthorizeGiftCardErrorKeys,
  CartAndItemErrorExtensions,
  CartErrorDetail,
  CartErrorMsg,
  CreatePaymentMethodErrorKeys,
  DeleteUserAddressErrorKeys,
  FinalizePriceErrorKeys,
  GiftInfoErrorKeys,
  IsAddressValidForTaxKeys,
  ItemErrorDetail,
  LocalizationCheckoutErrors,
  RemoveDiscountErrorKeys,
  RetrieveAddressErrorKeys,
  SaveEnteredContactInfoErrorKeys,
  SavePaymentErrorKeys,
  SavePaymentOptionsErrorKeys,
  SaveShippingOptionsErrorKeys,
  SaveSuggestedAddressErrorKeys,
  ShopToCheckoutErrorKeys,
  SubmitOrderErrorKeys,
  SuggestedAddress,
  SuggestedAddressError,
  UpdateUserAddressErrorKeys,
  UserAddressVerificationErrorExtensions,
  UserAddressVerificationErrorKeys,
  VerifyAddressErrorExtensions,
  VerifyAddressErrorKeys,
  VerifyUserErrorKeys,
} from "v3/types/error";
import { parseTemplateAsString } from "@pepdirect/v3/helpers/localization";
import { genericErrorMsgEnglish } from "@pepdirect/v3/constants";

type defaultMapKeys =
  | "guestCartContainsSubscription"
  | "minimumPurchaseAmount"
  | "minimumPurchaseQuantity"
  | "lowStock"
  | "purchaseQtyLimit"
  | "outOfStock"
  | "zipCodeRestrictionOnItems"
  | "itemUnavailable"
  | "insufficientLoyaltyBalance"
  | "invalidLoyaltyReward"
  | "invalidGiftCard";

const defaultMap: {
  [key in defaultMapKeys]: {
    title: string;
    note: string;
    cart: string;
  };
} = {
  guestCartContainsSubscription: {
    cart:
      "There are subscription items in your cart that require a registered account. " +
      "To continue as a guest, please remove them from your cart.",
    title: "Account needed",
    note: "Subscriptions are only available for registered users. Please create an account to proceed.",
  },
  minimumPurchaseAmount: {
    cart: "You have not met the ${minValue} minimum for your order. Please update your cart to complete your order.",
    title: "",
    note: "",
  },
  minimumPurchaseQuantity: {
    cart: "Please ensure a minimum quantity of ${minValue} items to proceed with your purchase.",
    title: "",
    note: "",
  },
  lowStock: {
    cart:
      "There are items in your cart where the quantity selected is more " +
      "than we have in stock. Please adjust or remove these items to " +
      "continue.",
    title: "Only ${inventoryQty} Available",
    note: "Please update the quantity for this item or remove it from your cart to proceed.",
  },
  purchaseQtyLimit: {
    cart:
      "There are items in your cart where the quantity selected is more " +
      "than available for purchase. Please adjust or remove these items " +
      "to continue.",
    title: "Only ${inventoryQty} Available",
    note: "Please update the quantity for this item or remove it from your cart to proceed.",
  },
  outOfStock: {
    cart: "There are items in your cart that are sold out. Please remove them to continue.",
    title: "Out of stock",
    note: "Please remove this product to proceed.",
  },
  zipCodeRestrictionOnItems: {
    cart: "",
    title: "Not available in your area",
    note: "Please remove this product to proceed.",
  },
  itemUnavailable: {
    cart: "There are items unavailable for purchase in your cart. Please remove them to continue.",
    title: "Not available",
    note: "Please remove this product to proceed.",
  },
  insufficientLoyaltyBalance: {
    cart: "",
    title: "Not enough points",
    note:
      "Your total points are not enough to complete this purchase." +
      " Please remove points redemption items to continue.",
  },
  invalidLoyaltyReward: {
    cart: "",
    title: "Loyalty reward has expired",
    note:
      "A loyalty reward was applied to your order and has expired. " +
      "Please go back and select another reward to proceed with checkout.",
  },
  invalidGiftCard: {
    cart: "",
    title: "There was an issue with your gift card",
    note: "Please re-enter the information and try again.",
  },
};

export const getCartErrorMsg = (
  errorKey: ShopToCheckoutErrorKeys | SubmitOrderErrorKeys,
  minValue?: number,
  localization?: LocalizationCheckoutErrors
): CartErrorMsg | null => {
  switch (errorKey) {
    case "guestCartContainsSubscription":
      return {
        alertType: "error",
        msg:
          localization?.guestCartContainsSubscription?.cartText ||
          defaultMap.guestCartContainsSubscription.cart,
        errorKey,
      };
    case "minimumPurchaseAmount":
      return {
        alertType: "error",
        msg: parseTemplateAsString(
          localization?.minimumPurchaseAmount ||
            defaultMap.minimumPurchaseAmount.cart,
          { minValue: convertFromCentsToDollars(minValue || 0) }
        ),
        errorKey,
      };
    case "minimumPurchaseQuantity":
      return {
        alertType: "error",
        msg: parseTemplateAsString(
          localization?.minimumPurchaseQuantity ||
            defaultMap.minimumPurchaseQuantity.cart,
          { minValue: minValue || "" }
        ),
        errorKey,
      };
    case "lowStock":
      return {
        alertType: "warning",
        msg: localization?.lowStock?.cartText || defaultMap.lowStock.cart,
        errorKey,
      };
    case "outOfStock":
      return {
        alertType: "error",
        msg: localization?.outOfStock?.cartText || defaultMap.outOfStock.cart,
        errorKey,
      };
    case "purchaseQtyLimit":
      return {
        alertType: "warning",
        msg:
          localization?.purchaseQtyLimit?.cartText ||
          defaultMap.purchaseQtyLimit.cart,
        errorKey,
      };
    case "archivedItemsInCart":
    case "assortmentExclusion":
    case "subscriptionOnlyInCart":
    case "unapprovedItemsInCart":
    case "virtualItemsInCart":
      return {
        alertType: "error",
        msg:
          localization?.itemUnavailable?.cartText ||
          defaultMap.itemUnavailable.cart,
        errorKey,
      };
    default:
      return null;
  }
};

export const getItemErrorMsg = (
  errorKey: ShopToCheckoutErrorKeys | SubmitOrderErrorKeys,
  numAvailable?: number,
  localization?: LocalizationCheckoutErrors
): ItemErrorMsg | null => {
  switch (errorKey) {
    case "guestCartContainsSubscription":
      return {
        alertType: "error",
        title:
          localization?.guestCartContainsSubscription?.itemHeadingText ||
          defaultMap.guestCartContainsSubscription.title,
        note:
          localization?.guestCartContainsSubscription?.itemNoteText ||
          defaultMap.guestCartContainsSubscription.note,
      };
    case "lowStock":
    case "purchaseQtyLimit":
      return {
        alertType: "warning",
        title: parseTemplateAsString(
          localization?.lowStock?.itemHeadingText || defaultMap.lowStock.title,
          { inventoryQty: numAvailable || "" }
        ),
        note: localization?.lowStock?.itemNoteText || defaultMap.lowStock.note,
      };
    case "outOfStock":
      return {
        alertType: "error",
        title:
          localization?.outOfStock?.itemHeadingText ||
          defaultMap.outOfStock.title,
        note:
          localization?.outOfStock?.itemNoteText || defaultMap.outOfStock.note,
      };
    case "zipCodeRestrictionOnItems":
    case "zipCodeRestrictionOnTenant":
      return {
        alertType: "error",
        title:
          localization?.zipCodeRestriction?.itemHeadingText ||
          defaultMap.zipCodeRestrictionOnItems.title,
        note:
          localization?.zipCodeRestriction?.itemNoteText ||
          defaultMap.zipCodeRestrictionOnItems.note,
      };
    case "assortmentExclusion":
    case "archivedItemsInCart":
    case "bundleItemWithoutBundle":
    case "bundleOnlyWithoutBundle":
    case "subscriptionOnlyInCart":
    case "unapprovedItemsInCart":
    case "virtualItemsInCart":
      return {
        alertType: "error",
        title:
          localization?.itemUnavailable?.itemHeadingText ||
          defaultMap.itemUnavailable.title,
        note:
          localization?.itemUnavailable?.itemNoteText ||
          defaultMap.itemUnavailable.note,
      };
    default:
      return null;
  }
};

const handleAddCartError = (
  cartErrors: CartErrorMsg[],
  errorKey: ShopToCheckoutErrorKeys | SubmitOrderErrorKeys,
  localization?: LocalizationCheckoutErrors,
  minValue?: number
): CartErrorMsg[] => {
  // add it if the error type doesn't already exist
  if (!cartErrors.some((err) => err.errorKey === errorKey)) {
    const msg = getCartErrorMsg(errorKey, minValue, localization);
    if (msg) cartErrors.push(msg);
  }
  return cartErrors;
};

const handleAddItemError = (
  itemErrors: ItemWithErrorMsgs[],
  type: ShopToCheckoutErrorKeys | SubmitOrderErrorKeys,
  details: ItemErrorDetail[],
  localization?: LocalizationCheckoutErrors
): ItemWithErrorMsgs[] => {
  details.map((detail) => {
    const { id, inventoryQty, purchaseQtyLimit } = detail;
    const msg = getItemErrorMsg(
      type,
      inventoryQty || purchaseQtyLimit,
      localization
    );
    if (!msg) return;

    // if item aready in errors list, add to that item's list of errors
    const item = itemErrors.find((item) => item.id === id);
    if (item) {
      item.errorMsgs.push(msg);
    } else {
      itemErrors.push({
        id,
        errorMsgs: [msg],
      });
    }
  });

  return itemErrors;
};

export const parseShopToCheckoutGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  let cartErrors: CartErrorMsg[] = [];
  let itemErrors: ItemWithErrorMsgs[] = [];
  let errorMsg = "";

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as CartAndItemErrorExtensions<ShopToCheckoutErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key, details } = errorDetail;
      switch (key) {
        // stock errors
        // cart and item level
        case ShopToCheckoutErrorKeys.lowStock:
        case ShopToCheckoutErrorKeys.outOfStock:
          cartErrors = handleAddCartError(cartErrors, key, localization);
          itemErrors = handleAddItemError(
            itemErrors,
            key,
            details as ItemErrorDetail[],
            localization
          );
          break;
        // min amount error
        // cart level
        case ShopToCheckoutErrorKeys.minimumPurchaseAmount:
          cartErrors = handleAddCartError(
            cartErrors,
            key,
            localization,
            (details as CartErrorDetail).minimumPurchaseAmount
          );
          break;
        // min qty error
        // cart level
        case ShopToCheckoutErrorKeys.minimumPurchaseQuantity:
          cartErrors = handleAddCartError(
            cartErrors,
            key,
            localization,
            (details as CartErrorDetail).minimumPurchaseQuantity
          );
          break;
        // no key defined on FE?
        // still show default error msg but also log to Sentry
        default:
          errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;
          logUnknownErrorCode(key, "shopToCheckout");
      }
    });
  });

  return {
    cartErrors,
    itemErrors,
    errorMsg,
  };
};

export const parseSubmitOrderGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  let cartErrors: CartErrorMsg[] = [];
  let itemErrors: ItemWithErrorMsgs[] = [];
  let loyaltyError: ItemErrorMsg | null = null;
  let giftCardError: ItemErrorMsg | null = null;
  let errorMsg = "";

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as CartAndItemErrorExtensions<SubmitOrderErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key, details } = errorDetail;
      switch (key) {
        // stock errors and additional item validations
        // cart and item level
        case SubmitOrderErrorKeys.archivedItemsInCart:
        case SubmitOrderErrorKeys.assortmentExclusion:
        case SubmitOrderErrorKeys.guestCartContainsSubscription:
        case SubmitOrderErrorKeys.lowStock:
        case SubmitOrderErrorKeys.outOfStock:
        case SubmitOrderErrorKeys.purchaseQtyLimit:
        case SubmitOrderErrorKeys.subscriptionOnlyInCart:
        case SubmitOrderErrorKeys.unapprovedItemsInCart:
        case SubmitOrderErrorKeys.virtualItemsInCart:
          cartErrors = handleAddCartError(cartErrors, key, localization);
          itemErrors = handleAddItemError(
            itemErrors,
            key,
            details as ItemErrorDetail[],
            localization
          );
          break;
        // zip code errors
        // item level
        case SubmitOrderErrorKeys.zipCodeRestrictionOnItems:
        case SubmitOrderErrorKeys.zipCodeRestrictionOnTenant:
          itemErrors = handleAddItemError(
            itemErrors,
            key,
            details as ItemErrorDetail[],
            localization
          );
          break;
        // min amount error
        // cart level
        case SubmitOrderErrorKeys.minimumPurchaseAmount:
          cartErrors = handleAddCartError(
            cartErrors,
            key,
            localization,
            (details as CartErrorDetail).minimumPurchaseAmount
          );
          break;
        // min qty error
        // cart level
        case SubmitOrderErrorKeys.minimumPurchaseQuantity:
          cartErrors = handleAddCartError(
            cartErrors,
            key,
            localization,
            (details as CartErrorDetail).minimumPurchaseQuantity
          );
          break;
        // unverified email
        // alert at top of page
        case SubmitOrderErrorKeys.unverifiedEmail:
          errorMsg =
            localization?.unverifiedEmail ||
            "Please enter a valid email address.";
          break;
        case SubmitOrderErrorKeys.paymentNotAccepted:
          errorMsg =
            localization?.paymentNotAccepted ||
            "There was a problem with your payment information. Please correct your card or payment address details and try again.";
          break;

        // loyalty errors
        case SubmitOrderErrorKeys.insufficientLoyaltyBalance:
          loyaltyError = {
            alertType: "error",
            title:
              localization?.insufficientLoyaltyBalance?.itemHeadingText ||
              defaultMap.insufficientLoyaltyBalance.title,
            note:
              localization?.insufficientLoyaltyBalance?.itemNoteText ||
              defaultMap.insufficientLoyaltyBalance.note,
          };
          break;
        case SubmitOrderErrorKeys.invalidLoyaltyReward:
          loyaltyError = {
            alertType: "error",
            title:
              localization?.invalidLoyaltyReward?.itemHeadingText ||
              defaultMap.invalidLoyaltyReward.title,
            note:
              localization?.invalidLoyaltyReward?.itemNoteText ||
              defaultMap.invalidLoyaltyReward.note,
          };
          break;
        case SubmitOrderErrorKeys.unableToApplyGiftCard:
        case SubmitOrderErrorKeys.noGiftCardBalance:
          giftCardError = {
            alertType: "error",
            title:
              localization?.invalidGiftCard?.itemHeadingText ||
              defaultMap.invalidGiftCard.title,
            note:
              localization?.invalidGiftCard?.itemNoteText ||
              defaultMap.invalidGiftCard.note,
          };
          break;
        // other
        // show default error msg
        case SubmitOrderErrorKeys.createSubmittedOrder:
        case SubmitOrderErrorKeys.shippingAddressValidation:
        case SubmitOrderErrorKeys.unableToDeleteCart:
        case SubmitOrderErrorKeys.loyaltyRedemptionFailure:
          errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;
          break;
        // no key defined on FE?
        // still show default error msg but also log to Sentry
        default:
          errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;
          logUnknownErrorCode(key, "submitOrder");
      }
    });
  });

  return {
    cartErrors,
    itemErrors,
    loyaltyError,
    giftCardError,
    errorMsg,
  };
};

interface ShopToCheckoutAndSubmitOrderErrors {
  cartErrors?: CartErrorMsg[];
  errorMsg: string;
  itemErrors?: ItemWithErrorMsgs[];
  loyaltyError?: ItemErrorMsg | null;
  giftCardError?: ItemErrorMsg | null;
  show404?: boolean;
  showEmptyCart?: boolean;
}

export const parseShopToCheckoutAndSubmitOrderGraphQLErrors = (
  graphQLErrors: GraphQLErrors,
  localization?: LocalizationCheckoutErrors
): ShopToCheckoutAndSubmitOrderErrors => {
  const mutationName = graphQLErrors[0].message;
  if (mutationName === "shopToCheckout") {
    return parseShopToCheckoutGraphQLErrors(
      graphQLErrors as GraphQLError[],
      localization
    );
  } else if (mutationName === "submitOrder") {
    return parseSubmitOrderGraphQLErrors(
      graphQLErrors as GraphQLError[],
      localization
    );
  }
  // default
  return { errorMsg: localization?.genericErrorMsg || genericErrorMsgEnglish };
};

export const parseVerifyAddressGraphQLErrors = (
  graphQLErrors: GraphQLError[]
): {
  suggestedAddress: SuggestedAddress | null;
  hasPoBoxError: boolean;
  hasGenericError: boolean;
} => {
  let suggestedAddress: SuggestedAddress | null = null;
  let hasPoBoxError = false;
  let hasGenericError = false;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as VerifyAddressErrorExtensions)?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key, details } = errorDetail;
      switch (key) {
        case VerifyAddressErrorKeys.addressVerification:
          suggestedAddress = null;
          break;
        case VerifyAddressErrorKeys.suggestedAddress:
          suggestedAddress =
            (details as SuggestedAddressError)?.suggested || null;
          break;
        case VerifyAddressErrorKeys.unableToUsePOBox:
          hasPoBoxError = true;
          break;
        case VerifyAddressErrorKeys.checkoutAddressVerificationError:
        case VerifyAddressErrorKeys.unableToSaveVerificationOnShippingAddress:
          hasGenericError = true;
          break;
        // no key defined on FE?
        // still show default error msg but also log to Sentry
        default:
          hasGenericError = true;
          logUnknownErrorCode(key, "verifyAddress");
      }
    });
  });

  return {
    suggestedAddress,
    hasPoBoxError,
    hasGenericError,
  };
};

export const parseAuthorizeGiftCardGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  let errorTitle = "Something went wrong";
  let errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<AuthorizeGiftCardErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case AuthorizeGiftCardErrorKeys.zeroBalance:
          errorTitle = "The gift card you entered has no remaining balance";
          errorMsg =
            localization?.discount?.discountNotFound ||
            "Please try another card, or continue to checkout.";
          break;
        case AuthorizeGiftCardErrorKeys.giftCardMismatch:
        case AuthorizeGiftCardErrorKeys.unableToApplyGiftCard:
          errorTitle = "Invalid card number or PIN";
          errorMsg = "Please double-check your information and try again.";
          break;
        default:
          logUnknownErrorCode(key, "authorizeGiftCard");
      }
    });
  });

  return { errorMsg, errorTitle };
};

export const parseApplyDiscountGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  let errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<ApplyDiscountErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case ApplyDiscountErrorKeys.discountNotFound:
          errorMsg =
            localization?.discount?.discountNotFound ||
            "Items in your cart may be excluded, have a discount, or this code may be invalid.";
          break;
        case ApplyDiscountErrorKeys.unableToApplyDiscount:
        case ApplyDiscountErrorKeys.unableToSaveDiscount:
          break;
        default:
          logUnknownErrorCode(key, "applyDiscount");
      }
    });
  });

  return { errorMsg };
};

export const parseRemoveDiscountGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<RemoveDiscountErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case RemoveDiscountErrorKeys.unableToRemoveDiscount:
          break;
        default:
          logUnknownErrorCode(key, "removeDiscount");
      }
    });
  });

  return { errorMsg };
};

export const parseRetrieveAddressGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  let errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<RetrieveAddressErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case RetrieveAddressErrorKeys.unableToUsePOBox:
          errorMsg =
            localization?.unableToUsePoBox ||
            "At this time we do not ship to P.O. boxes.";
          break;
        default:
          logUnknownErrorCode(key, "retrieveAddress");
      }
    });
  });

  return { errorMsg };
};

export const parseSaveShippingOptionsGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<SaveShippingOptionsErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case SaveShippingOptionsErrorKeys.unableToSaveShippingOptions:
          break;
        default:
          logUnknownErrorCode(key, "saveShippingOptions");
      }
    });
  });

  return { errorMsg };
};

export const parseFinalizePriceGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<FinalizePriceErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case FinalizePriceErrorKeys.unableToSetStage:
          break;
        default:
          logUnknownErrorCode(key, "finalizePrice");
      }
    });
  });

  return { errorMsg };
};

export const parseSavePaymentOptionsGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<SavePaymentOptionsErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case SavePaymentOptionsErrorKeys.unableToSavePaymentOptions:
          break;
        default:
          logUnknownErrorCode(key, "savePaymentOptions");
      }
    });
  });

  return { errorMsg };
};

export const parseSaveEnteredContactInfoGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (
        error.extensions as GenericErrorExtensions<SaveEnteredContactInfoErrorKeys>
      )?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case SaveEnteredContactInfoErrorKeys.unableToSaveEnteredContactInfo:
          break;
        default:
          logUnknownErrorCode(key, "saveEnteredContactInfo");
      }
    });
  });

  return { errorMsg };
};

export const parseSaveSuggestedAddressGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (
        error.extensions as GenericErrorExtensions<SaveSuggestedAddressErrorKeys>
      )?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case SaveSuggestedAddressErrorKeys.unableToSaveEnteredContactInfo:
          break;
        default:
          logUnknownErrorCode(key, "saveSuggestedAddress");
      }
    });
  });

  return { errorMsg };
};

export const parseSaveGiftInfoGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<GiftInfoErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case GiftInfoErrorKeys.giftingDisabled:
          break;
        default:
          logUnknownErrorCode(key, "saveGiftInfo");
      }
    });
  });

  return { errorMsg };
};

export const parseRemoveGiftInfoGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<GiftInfoErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case GiftInfoErrorKeys.giftingDisabled:
          break;
        default:
          logUnknownErrorCode(key, "removeGiftInfo");
      }
    });
  });

  return { errorMsg };
};

export const parseApplySavedShippingAddressGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (
        error.extensions as GenericErrorExtensions<ApplySavedShippingAddressErrorKeys>
      )?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case ApplySavedShippingAddressErrorKeys.addressNotFound:
          break;
        default:
          logUnknownErrorCode(key, "applySavedShippingAddress");
      }
    });
  });

  return { errorMsg };
};

export const parseSavePaymentGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: LocalizationCheckoutErrors
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<SavePaymentErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case SavePaymentErrorKeys.missingBillingAddress:
        case SavePaymentErrorKeys.paymentDataError:
        case SavePaymentErrorKeys.unableToSavePayment:
          break;
        default:
          logUnknownErrorCode(key, "savePayment");
      }
    });
  });

  return { errorMsg };
};

export const parseVerifyUserGraphQLErrors = (graphQLErrors: GraphQLError[]) => {
  let errorKey = VerifyUserErrorKeys.tokenNotFound;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<VerifyUserErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case VerifyUserErrorKeys.tokenExpired:
        case VerifyUserErrorKeys.tokenNotFound:
          errorKey = key;
          break;
        default:
          errorKey = VerifyUserErrorKeys.tokenNotFound;
          logUnknownErrorCode(key, "verifyUser");
      }
    });
  });

  return { errorKey };
};

export const parseIsAddressValidForTaxGraphQLErrors = (
  graphQLErrors: GraphQLError[]
) => {
  let hasInvalidZip = false;
  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<IsAddressValidForTaxKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case IsAddressValidForTaxKeys.invalidZip:
          hasInvalidZip = true;
          break;
        default:
          logUnknownErrorCode(key, "isAddressValidForTax");
      }
    });
  });
  return { hasInvalidZip };
};

export const parseAndLogUnknownErrors = (e: unknown, operationName: string) => {
  if (e instanceof ApolloError) {
    const { graphQLErrors } = e;
    if (graphQLErrors.length) {
      graphQLErrors.forEach((error: GraphQLError) => {
        const errorDetails = (
          error.extensions as GenericErrorExtensions<string>
        )?.details;
        if (!errorDetails || !Array.isArray(errorDetails)) return;
        // logging for malformed errorDetails in v3 errorLink
        // leaving v2 the same as it is not expected to be on the new error policy
        errorDetails?.forEach((errorDetail) => {
          const { key } = errorDetail;
          logUnknownErrorCode(key, operationName);
        });
      });
    }
  }
};

export const parseDeleteUserAddressErrors = (graphQLErrors: GraphQLError[]) => {
  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<DeleteUserAddressErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case DeleteUserAddressErrorKeys.addressSubscriptionAssociation:
          break;
        default:
          logUnknownErrorCode(key, "deleteUserError");
      }
    });
  });
};

export const parseCreatePaymentMethodGraphQLErrors = (
  graphQLErrors: GraphQLError[]
) => {
  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<CreatePaymentMethodErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;

    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case CreatePaymentMethodErrorKeys.processingError:
          break;
        default:
          logUnknownErrorCode(key, "createPaymentMethod");
      }
    });
  });

  return;
};

export const parseUpdateUserAddressErrors = (
  graphQLErrors: GraphQLError[],
  localization: LocalizationCheckoutErrors | undefined
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;
  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as ChangesetErrorExtensions<UpdateUserAddressErrorKeys>)
        ?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case UpdateUserAddressErrorKeys.notFound:
          break;
        case ChangesetErrorKey:
          logChangesetError("updateUserAddress", errorDetail.details);
          break;
        default:
          logUnknownErrorCode(key, "updateUserAddress");
      }
    });
  });
  return { errorMsg };
};

export const parsePersistTemporaryCartErrors = (
  graphQLErrors: GraphQLError[]
) => {
  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (
        error.extensions as GenericErrorExtensions<PersistTemporaryCartErrorKeys>
      )?.details || [];
    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case PersistTemporaryCartErrorKeys.cartIsNotTemporary:
          break;
        default:
          logUnknownErrorCode(key, "persistTemporaryCart");
      }
    });
  });
};

export const parseCreateUserAddressErrors = (
  graphQLErrors: GraphQLError[],
  localization: LocalizationCheckoutErrors | undefined
) => {
  const errorMsg = localization?.genericErrorMsg || genericErrorMsgEnglish;
  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as ChangesetErrorExtensions)?.details || [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case ChangesetErrorKey:
          logChangesetError("createUserAddress", errorDetail.details);
          break;
        default:
          logUnknownErrorCode(key, "createUserAddress");
      }
    });
  });

  return { errorMsg };
};

export const parseUserAddressVerificationErrors = (
  graphQLErrors: GraphQLError[]
): {
  suggestedAddress: SuggestedAddress | null;
  hasGenericError: boolean;
} => {
  let suggestedAddress: SuggestedAddress | null = null;
  let hasGenericError = false;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as UserAddressVerificationErrorExtensions)?.details ||
      [];

    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case UserAddressVerificationErrorKeys.suggestedAddress:
          suggestedAddress = errorDetail.details.suggested || null;
          break;
        case UserAddressVerificationErrorKeys.addressNotFound:
          break;
        default:
          hasGenericError = true;
          logUnknownErrorCode(key, "userAddressVerification");
      }
    });
  });

  return { suggestedAddress, hasGenericError };
};
