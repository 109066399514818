import React, { useEffect, useRef } from "react";
import { enUS, pl } from "date-fns/locale";
import { setDefaultOptions } from "date-fns";
import Router, { useRouter } from "next/router";
import getConfig from "next/config";
import App, {
  AppProps,
  AppInitialProps,
  AppContext as NextAppContext,
} from "next/app";
import NProgress from "nprogress";
import TagManager from "react-gtm-module";
import { StyleSheetManager } from "styled-components";
import { shouldForwardProp } from "@pepdirect/v3/helpers/transientProps";

// context
import { ModalContext } from "context/modal";
import { BraintreeContext } from "context/braintree";
import { DiscountsContext } from "context/discounts";
import { CreditContext } from "context/credit";
import { InvoicingContext } from "context/invoicing";
import { CheckoutContext } from "context/checkout";
import { useModalProvider } from "context/hooks/useModalProvider";
import { useBraintreeProvider } from "context/hooks/useBraintreeProvider";
import { useDiscountsProvider } from "context/hooks/useDiscountsProvider";
import { useCreditProvider } from "context/hooks/useCreditProvider";
import { useCheckoutProvider } from "context/hooks/useCheckoutProvider";
import { useAutocompleteProvider } from "context/hooks/useAutocompleteProvider";
import { useBlockedItemsProvider } from "context/hooks/useBlockedItemsProvider";
import { BlockedItemsContext } from "context/blockedItems";
import { AutocompleteContext } from "context/autocomplete";
import { useInvoicingProvider } from "context/hooks/useInvoicingProvider";
import { StripeContext } from "context/stripe";
import { useStripeProvider } from "context/hooks/useStripeProvider";
// We need to share one tenant, banner, localization, and portal errors across v2 and v3 since we have both v2 and v3 pages in portal
import { useTenantProvider } from "v3/context/hooks/useTenantProvider";
import { TenantContext } from "v3/context/tenant";
import { useBannerProvider } from "v3/context/hooks/useBannerProvider";
import { BannerContext } from "v3/context/banner";
import { LocalizationContext } from "v3/context/localization";
import { useLocalizationProvider } from "v3/context/hooks/useLocalizationProvider";
import { PortalErrorContext } from "v3/context/portalError";
import { usePortalErrorProvider } from "v3/context/hooks/usePortalErrorProvider";

import { generateFontFaces } from "@pepdirect/helpers/fonts";

import { ThemeProvider, createGlobalStyle } from "styled-components";
import { ThemeInterface } from "@pepdirect/ui/theme";
import { getTheme } from "helpers/theme";
import { RootModal } from "components/Modals/Modal";
import st from "styles/modules/app.module.scss";
import "styles/index.scss";

// V3 imports
import { ThemeInterface as V3ThemeInterface } from "@pepdirect/v3/theme";
import { getTheme as getV3Theme } from "v3/helpers/theme";
import V3App from "v3/components/App";

import v3Client, {
  updateBasePathOnClient as updateBasePathOnV3Client,
  updateDevBasePathOnClient as updateDevBasePathOnV3Client,
} from "v3/services/graphql";

// styling overrides
import "styles/stylingOverrides.scss";

/* Importing and customizing nprogress */
import "styles/nprogress.scss";
import { getBasePath, isServerSide } from "helpers/api";
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import client, {
  updateBasePathOnClient,
  updateDevBasePathOnClient,
} from "services/graphql";

import { useFontTracking } from "@pepdirect/helpers/useFontTracking";
import { FontTrackingiFrame } from "@pepdirect/ui/fontTracking";
import { addGitShaToWindow } from "@pepdirect/helpers/gitSha";
import createSsrClient from "services/graphql/ssr-client";
import { environment, isLocalEnv } from "config";
import {
  BrandConfigTrackingFonts,
  CurrentUserIdDocument,
  CurrentUserIdQuery,
  LocalizationDocument,
  LocalizationQuery,
  LocalizationQueryVariables,
  PaymentProcessor,
  TenantDocument,
  TenantQuery,
  TenantQueryVariables,
} from "services/graphql/generated";

addGitShaToWindow();

// Init progress bar
NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// Scroll to top after router.push();
Router.events.on("routeChangeComplete", () => window?.scrollTo(0, 0));

interface CheckoutPortalAppInitialProps extends AppInitialProps {
  devBasePath?: string;
  localization: LocalizationQuery["localization"] | null;
  tenant: TenantQuery["tenant"];
  theme: ThemeInterface;
  v3Theme: V3ThemeInterface;
}

interface CheckoutPortalAppProps extends AppProps {
  devBasePath?: string;
  localization: LocalizationQuery["localization"] | null;
  tenant: TenantQuery["tenant"];
  theme: ThemeInterface;
  v3Theme: V3ThemeInterface;
}

const GlobalStyle = createGlobalStyle`
  body {
    ${({ theme }) => `
    background-color: ${theme.page?.backgroundColor || ""};
    font-size: ${theme.typography.body?.fontSize || ""};
    color: ${theme.typography.body?.color || ""};
    line-height: ${theme.typography.body?.lineHeight || ""};
    `}
  }
`;

CheckoutPortal.getInitialProps = async (
  appContext: NextAppContext
): Promise<CheckoutPortalAppInitialProps> => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  let userId: NonNullable<CurrentUserIdQuery["currentUser"]>["id"] | null =
    null;
  let gqlClient: ApolloClient<NormalizedCacheObject>;
  if (isServerSide(appContext)) {
    gqlClient = createSsrClient(appContext.ctx.req);
  } else {
    gqlClient = client;
  }
  let devBasePath;

  try {
    const userData = await gqlClient.query<CurrentUserIdQuery>({
      query: CurrentUserIdDocument,
    });
    userId = userData?.data?.currentUser?.id || null;
  } catch (e) {
    // logged in errorLink
  }

  // When we add cookies to the request for tenant query,
  // if the cookie is not valid, then it returns 401 unauthenticated error.
  // To prevent this, we detach cookies from the request before making request to them
  // if the user cookie is not valid. If cookie is not set, then this would not be a problem.
  if (!userId && isServerSide(appContext)) {
    gqlClient = createSsrClient(appContext.ctx.req, true);
  }

  /* Set base path for server-side axios and GQL clients */
  if (isServerSide(appContext)) {
    devBasePath = getBasePath(appContext.ctx.req);
  }

  const { ctx } = appContext;
  const acceptLanguageHeader = ctx?.req?.headers?.[
    "pepdirect-accept-language"
  ] as string;

  /* eslint-disable no-useless-catch */
  try {
    const [localization, tenant] = await Promise.all([
      gqlClient.query<LocalizationQuery, LocalizationQueryVariables>({
        query: LocalizationDocument,
        ...(acceptLanguageHeader && {
          context: {
            headers: {
              "accept-language": acceptLanguageHeader,
            },
          },
        }),
      }),
      gqlClient.query<TenantQuery, TenantQueryVariables>({
        query: TenantDocument,
        ...(acceptLanguageHeader && {
          context: {
            headers: {
              "accept-language": acceptLanguageHeader,
            },
          },
        }),
      }),
    ]);

    const theme = await getTheme(tenant.data.tenant?.id);
    const v3Theme = await getV3Theme(tenant.data.tenant?.id);

    return {
      ...appProps,
      devBasePath,
      localization: localization.data.localization || null,
      tenant: tenant.data.tenant,
      theme,
      v3Theme,
    };
  } catch (e) {
    // make sure to re-throw after catch logs in errorLink so we show 500 error
    throw e;
  }
};

export default function CheckoutPortal({
  Component,
  devBasePath,
  localization,
  pageProps,
  tenant,
  theme,
  v3Theme,
}: CheckoutPortalAppProps): JSX.Element {
  const devBasePathRef = useRef(devBasePath);

  const gtmId = tenant?.analyticsConfig?.gtm?.containerId;
  if (gtmId && typeof window !== "undefined" && !window.dataLayer) {
    TagManager.initialize({ gtmId });
  }
  const { pathname } = useRouter();

  const title = tenant?.brandConfig.title || "Checkout Portal";
  const locale =
    localization?.currentLocale || localization?.defaultLocale || "en";
  const trackingFonts = tenant?.brandConfig.trackingFonts || [];
  const fonts = tenant?.brandConfig?.customizations.fonts || [];
  const trackFontResources = useFontTracking(trackingFonts, title);
  const defaultCurrencyCode =
    tenant?.checkout?.paymentOptions?.defaultCurrencyCode || "USD";

  useEffect(() => {
    if (setDefaultOptions)
      setDefaultOptions({ locale: locale === "pl" ? pl : enUS });
  }, [locale]);

  useEffect(() => {
    // exposes pod hostname to browser window
    if (window) {
      const { publicRuntimeConfig } = getConfig();
      window._pepdirect = {
        ...window._pepdirect,
        hostname: publicRuntimeConfig.hostname,
      };
    }

    // add class to body to indicate whether user's browser is IE or not, for IE bugs
    document.body.classList.add(
      /MSIE|Trident/.test(window.navigator.userAgent)
        ? "is-ie-browser"
        : "is-modern-browser"
    );
  }, []);

  // this is used for changing the background-color
  // on the checkout and order confirmation page
  useEffect(() => {
    if (pathname.includes("[appId]")) {
      document.body.classList.add("is-checkout");
    } else {
      document.body.classList.remove("is-checkout");
    }
  }, [pathname]);

  /* Set base path for client-side axios instance */
  if (!(typeof window === "undefined")) {
    // special dev configuration to allow all apps to run concurrently locally
    if (environment === "dev") {
      if (devBasePathRef.current) {
        updateDevBasePathOnClient({
          client,
          basePath: devBasePathRef.current,
          locale,
        });
        updateDevBasePathOnV3Client({
          client: v3Client,
          basePath: devBasePathRef.current,
          locale,
        });
      }
    } else {
      updateBasePathOnClient({ client, locale });
      updateBasePathOnV3Client({ client: v3Client, locale });
    }
  }

  const isStripeTenant =
    tenant?.paymentConfig.processor === PaymentProcessor.Stripe;

  const { tenantContextValue } = useTenantProvider(tenant);
  const { modalContextValue } = useModalProvider();
  const { localizationContextValue } = useLocalizationProvider(
    localization,
    defaultCurrencyCode
  );
  const { braintreeContextValue } = useBraintreeProvider(client);
  const { stripeContextValue } = useStripeProvider(client, isStripeTenant);
  const { discountsContextValue } = useDiscountsProvider();
  const { bannerContextValue } = useBannerProvider();
  const { creditContextValue } = useCreditProvider();
  const { invoicingContextValue } = useInvoicingProvider();
  const { blockedItemsContextValue } = useBlockedItemsProvider();
  const { autocompleteContextValue } = useAutocompleteProvider();
  const { checkoutContextValue } = useCheckoutProvider(
    client,
    tenantContextValue,
    discountsContextValue,
    modalContextValue,
    autocompleteContextValue
  );
  const { portalErrorContextValue } = usePortalErrorProvider(localization);

  if (pathname.includes("/v3/")) {
    return (
      <V3App
        // We need to share one banner and localization across v2 and v3
        // since we have both v2 and v3 pages in portal
        bannerContextValue={bannerContextValue}
        localizationContextValue={localizationContextValue}
        // share a single tenant everywhere
        tenantContextValue={tenantContextValue}
        // share portal error handling everywhere
        portalErrorContextValue={portalErrorContextValue}
        fonts={fonts}
        isStripeTenant={isStripeTenant}
        theme={v3Theme}
        title={title}
        // TODO: BE needs to fix this type to not have Maybe inside the array
        trackingFonts={trackingFonts as BrandConfigTrackingFonts[]}
      >
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <div className={st.pageLayout}>
            <Component {...pageProps} />
          </div>
        </StyleSheetManager>
      </V3App>
    );
  }

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: generateFontFaces(
            tenant?.brandConfig?.customizations.fonts || []
          ),
        }}
      />
      {trackFontResources.map((resource) => (
        <FontTrackingiFrame
          resource={resource}
          key={resource}
          isLocalEnv={isLocalEnv}
        />
      ))}
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <TenantContext.Provider value={tenantContextValue}>
            <LocalizationContext.Provider value={localizationContextValue}>
              <ModalContext.Provider value={modalContextValue}>
                <BraintreeContext.Provider value={braintreeContextValue}>
                  <StripeContext.Provider value={stripeContextValue}>
                    <DiscountsContext.Provider value={discountsContextValue}>
                      <BannerContext.Provider value={bannerContextValue}>
                        <InvoicingContext.Provider
                          value={invoicingContextValue}
                        >
                          <CreditContext.Provider value={creditContextValue}>
                            <BlockedItemsContext.Provider
                              value={blockedItemsContextValue}
                            >
                              <CheckoutContext.Provider
                                value={checkoutContextValue}
                              >
                                <PortalErrorContext.Provider
                                  value={portalErrorContextValue}
                                >
                                  <AutocompleteContext.Provider
                                    value={autocompleteContextValue}
                                  >
                                    <StyleSheetManager
                                      shouldForwardProp={shouldForwardProp}
                                    >
                                      <div className={st.pageLayout}>
                                        <Component {...pageProps} />
                                      </div>
                                      <RootModal />
                                      <GlobalStyle />
                                    </StyleSheetManager>
                                  </AutocompleteContext.Provider>
                                </PortalErrorContext.Provider>
                              </CheckoutContext.Provider>
                            </BlockedItemsContext.Provider>
                          </CreditContext.Provider>
                        </InvoicingContext.Provider>
                      </BannerContext.Provider>
                    </DiscountsContext.Provider>
                  </StripeContext.Provider>
                </BraintreeContext.Provider>
              </ModalContext.Provider>
            </LocalizationContext.Provider>
          </TenantContext.Provider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
}
