// USE FOR BOTH V2 AND V3
export const PageRoutes = {
  // these are v3
  addPayment: "/v3/dashboard/account/add-payment",
  changePassword: "/v3/dashboard/account/change-password",
  editAddress: (addrId: string) =>
    `/v3/dashboard/account/edit-address/${addrId}`,
  exchange: "/v3/dashboard/exchange",
  exchangeDetails: (exchangeId: string) =>
    `/v3/dashboard/exchange/${exchangeId}`,
  machines: "/v3/dashboard/machines",
  orderDetails: (orderId: string) => `/v3/dashboard/orders/${orderId}`,
  orders: "/v3/dashboard/orders",
  payments: "/v3/dashboard/account/payments",
  signIn: "/v3/sign-in",
  signUp: "/v3/sign-up",
  subscriptionAddPayment: (subId: string) =>
    `/v3/dashboard/subscriptions/${subId}/payments/add-payment`,
  subscriptionAddresses: (subId: string) =>
    `/v3/dashboard/subscriptions/${subId}/addresses`,
  subscriptionDetail: (subId: string) => `/v3/dashboard/subscriptions/${subId}`,
  subscriptionEditAddress: (subId: string, addrId: string) =>
    `/v3/dashboard/subscriptions/${subId}/addresses/${addrId}`,
  subscriptionList: "/v3/dashboard/subscriptions",
  subscriptionPayments: (subId: string) =>
    `/v3/dashboard/subscriptions/${subId}/payments`,
  updatePassword: "/v3/update-password",
  addAddress: "/v3/dashboard/account/add-address",
  addresses: "/v3/dashboard/account/addresses",
  account: "/v3/dashboard/account",
};
