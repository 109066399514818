import React, { FC, ReactNode } from "react";
import { useRouter } from "next/router";
import { ChevronIconSvg } from "../../icons";
import { Form } from "react-final-form";
import { FieldInput } from "../../fields/FieldInput";
import { Button } from "../../Button";
import { Heading } from "../../Heading";
import {
  getSortedTermsByLocation,
  TermsAndConditions,
} from "../../terms/TermsAndConditions";
import {
  BrandConfigCustomizationsImage,
  FormsSignInLocalization,
  FormsValidationLocalization,
  CustomText,
  PortalAltsLocalization,
} from "../../../types/graphql/generated";
import { validateWithYup, getLoginSchema } from "../../../helpers/validation";
import { formFields } from "../../../constants";
import {
  StyledAccountLogo,
  StyledAlert,
  StyledError,
  StyledForm,
  StyledLinkWrapper,
  StyledLoginButton,
  StyledLoginForm,
  StyledLogo,
  StyledResetPassword,
} from "./index.styled";
import { AlertTypes } from "../../alerts/Alert";

// 12/16/24 PCK-1215: Temporary alert copy. Will be removed after some time.
// Account Update
const accountUpdateTitle = "Aktualizacja konta";
// Our account requirements have changed. You may need to change your password.
const accountUpdateInfo =
  "Nasze wymagania dotyczące konta uległy zmianie. Być może będziesz musiał zmienić hasło.";

interface LoginFormProps {
  accountLogo?: BrandConfigCustomizationsImage;
  alertDetails?: {
    alertType: AlertTypes;
    text: string;
  };
  altsLocalization?: PortalAltsLocalization | null;
  children?: ReactNode;
  customText: CustomText[];
  error: string | ReactNode;
  goToForgotPassword: () => void;
  isLoading: boolean;
  isSodaStreamPL?: boolean;
  logoSrc?: string;
  onSubmit: (formValues: LoginFormValues) => void;
  privacyUrl?: string;
  signInLocalization?: FormsSignInLocalization | null;
  signinPageTitle?: string | null;
  validationLocalization?: FormsValidationLocalization | null;
}

export interface LoginFormValues {
  email: string;
  password: string;
}

export const LoginForm: FC<LoginFormProps> = ({
  accountLogo,
  alertDetails,
  altsLocalization,
  children,
  error,
  goToForgotPassword,
  isLoading,
  isSodaStreamPL,
  customText,
  logoSrc,
  onSubmit,
  signInLocalization,
  signinPageTitle,
  validationLocalization,
}) => {
  const { accountAltText = "Account", shopLogoAltText = "shop logo" } =
    altsLocalization || {};
  const {
    emailInputPlaceholderText = "Enter email address",
    passwordInputPlaceholderText = "Enter password",
    loginButtonText = "Login",
    forgotPasswordButtonText = "Forgot your password?",
  } = signInLocalization || {};

  const router = useRouter();

  return (
    <StyledLoginForm>
      {!!logoSrc && <StyledLogo src={logoSrc} alt={shopLogoAltText} />}

      {!!accountLogo && (
        <div>
          <StyledLinkWrapper
            onClick={() => {
              router.back();
            }}
          >
            <ChevronIconSvg />
          </StyledLinkWrapper>
          <StyledAccountLogo src={accountLogo.src} alt={accountAltText} />
        </div>
      )}

      {alertDetails && (
        <StyledAlert bold closable type={alertDetails.alertType}>
          {alertDetails.text}
        </StyledAlert>
      )}

      {isSodaStreamPL && (
        <StyledAlert bold closable title={accountUpdateTitle} type="info">
          <div>{accountUpdateInfo}</div>
        </StyledAlert>
      )}

      <Heading level="h2" size="l">
        {signinPageTitle}
      </Heading>
      <Form
        onSubmit={(formValues: LoginFormValues) => onSubmit(formValues)}
        validate={validateWithYup(getLoginSchema(validationLocalization))}
        render={({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <FieldInput
              {...formFields.email}
              placeholder={emailInputPlaceholderText}
            />
            <FieldInput
              {...formFields.password}
              type="password"
              placeholder={passwordInputPlaceholderText}
            />
            <TermsAndConditions
              terms={getSortedTermsByLocation({
                location: "login",
                terms: customText,
              })}
              type="info"
            >
              <StyledLoginButton>
                <Button htmlType="submit" loading={isLoading} fullWidth>
                  {loginButtonText}
                </Button>
              </StyledLoginButton>
              <StyledResetPassword>
                <Button type="text" onClick={goToForgotPassword}>
                  {forgotPasswordButtonText}
                </Button>
              </StyledResetPassword>
            </TermsAndConditions>
            {error && <StyledError>{error}</StyledError>}
          </StyledForm>
        )}
      />
      {children}
    </StyledLoginForm>
  );
};
